<template>
  <div class="info flex-column no-expand full">
    <div class="avatar">
      <van-image
        round
        width="2.5rem"
        height="2.5rem"
        fit="cover"
        :src="user.avatar"
      />
    </div>
    <div class="welcome-text">
      <span>欢迎回家吃饭, {{ user.nickname }}!</span>
    </div>
    <div style="padding: 1.8rem 0">
      <span style="font-size: 1rem; color: #fff"> 请选择就餐人数 </span>
    </div>
    <div class="choose-bar">
      <div
        class="common-card three-d-shadow-mid flex-column"
        style="align-items: center"
      >
        <grid class="overwrite-grid" :border="false" :column-num="4">
          <grid-item v-for="value in 12" :key="value">
            <div
              @click="num = value"
              :class="{
                'num-box': true,
                'num-box-selected': num === value.toString(),
              }"
            >
              {{ value }}
            </div>
          </grid-item>
        </grid>
        <div class="flex-row" style="width: 100%">
          <field
            v-model="num"
            type="digit"
            class="input-transition"
            :style="{
              flex: isUserInput ? 5 : 0,
              padding: isUserInput ? '0 16px' : 0,
            }"
          />
          <van-button
            size="small"
            type="primary"
            class="no-shrink input-transition"
            style="flex: 1"
            @click="isUserInput = !isUserInput"
          >
            {{ isUserInput ? "取消" : "自行输入" }}
          </van-button>
        </div>
      </div>
      <div style="padding-top: 0.6rem; width: 100%">
        <van-button
          round
          size="large"
          icon="records"
          :loading="loading"
          loading-text="小二正在处理..."
          :disabled="num <= 0"
          type="primary"
          style="width: 100%"
          :color="num <= 0 ? '#C4C4C4' : undefined"
          class="three-d-shadow-dark"
          @click="confirm"
        >
          开始点餐
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Image as VanImage,
  Grid,
  GridItem,
  Field,
  Button as VanButton,
  Notify,
} from "vant";
import { mapState } from "vuex";
import store from "@/store";
export default {
  name: "Info",
  data() {
    return {
      num: 0,
      isUserInput: false,
      loading: false,
    };
  },
  components: {
    VanImage,
    Grid,
    GridItem,
    Field,
    VanButton,
  },
  computed: {
    ...mapState({
      user: (state) => state.base.currentUser,
    }),
  },
  beforeRouteEnter: (to, from, next) => {
    if (store.state.base.eaterNum > 0) {
      next("/home");
    } else {
      next();
    }
  },
  methods: {
    confirm() {
      const num = Number(this.num);
      if (!num || num <= 0) {
        Notify({ type: "danger", message: "输入人数不合法！" });
      } else {
        this.loading = true;
        this.$store
          .dispatch("base/getMenu", num)
          .then((res) => {
            this.loading = false;
            if (res) {
              if (this.$store.state.app.ws) {
                this.$store.state.app.ws.send(
                  JSON.stringify({
                    type: "generate",
                    num,
                    orderId: res,
                  })
                );
              }
              this.$router.push("/shopping");
            }
          })
          .catch(() => {
            this.loading = false;
            this.$toast.fail("请求失败，请稍后再试");
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  box-sizing: border-box;
  align-items: center;
  padding: 0.6rem;
  .avatar {
    padding: 0.5rem 0;
  }
  .welcome-text {
    color: #fff;
    font-size: 0.6rem;
  }
  .choose-bar {
    width: 100%;
    .num-box {
      font-size: 0.8rem;
      width: 100%;
      height: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.2rem;
      border: 2px solid @primary-color;
    }
    .num-box-selected {
      background-color: @primary-color;
      color: white;
    }
    .input-transition {
      border-radius: 0.2rem;
      transition: all 0.4s ease-in-out;
      /deep/.van-field__body {
        height: 100%;
      }
    }
  }
  .overwrite-grid {
    /deep/.van-grid-item__content {
      background-color: transparent;
    }
  }
}
</style>
