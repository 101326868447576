<template>
  <div class="about" style="background-color: white; width: 100%">
    <div class="pageTitle">
      <div>
        <img
          src="../assets/image/logo.png"
          style="padding-left: 2rem; height: 6rem; width: auto"
        />
      </div>
    </div>
    <van-form @submit="onLogin" class="login">
      <div v-if="loginType == 0" key="login_pwd" class="login_pwd">
        <van-field
          v-model="username"
          name="username"
          label="账号"
          placeholder="请输入账号"
          clearable
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="password"
          type="password"
          name="password"
          label="密码"
          placeholder="请输入密码"
          maxlength="16"
          clearable
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="vcode"
          name="vcode"
          label="验证码"
          placeholder="请输入验证码"
          maxlength="16"
          clearable
          :rules="[{ required: true }]"
        >
          <div slot="button">
            <img :src="captchaCode" @click="getVerifyCode" />
          </div>
        </van-field>
      </div>
      <div v-if="loginType == 1" key="login_sms" class="login_sms">
        <van-field
          v-model="telephone"
          name="telephone"
          label="手机号"
          placeholder="请输入手机号"
          clearable
          :rules="[
            { required: true, message: '手机号不能为空' },
            /*{ validator: validatePhone, message: '请输入正确的手机号' },*/
          ]"
        />
        <van-field
          v-model="sms"
          center
          name="captcha"
          label="验证码"
          placeholder="请输入短信验证码"
          clearable
          :rules="[{ required: true, message: '验证码不能为空' }]"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              round
              color="#25c9c3"
              :disabled="vcodeButtonDisabled"
              @click.prevent="sendSMSCode"
              >{{ vcodeText }}</van-button
            >
          </template>
        </van-field>
      </div>

      <div class="btn">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          color="#25c9c3"
          style="background: #518cb3"
          >登录</van-button
        >
      </div>
      <div class="pwd2sms">
        <span @click="loginType = loginType == 0 ? 1 : 0">{{
          loginType == 0 ? "手机登录/注册" : "账号密码登录"
        }}</span>
      </div>
    </van-form>
  </div>
</template>

<script>
import { login, getCaptcha, sendSMSCode } from "@/api/merchant";
import {
  Button as VanButton,
  Dialog,
  Field as VanField,
  Form as VanForm,
  /*Tab as VanTab,
  Tabs as VanTabs,*/
  Notify,
  //Icon as VanIcon,
} from "vant";
import Cookies from "js-cookie";
import { loginOrRegister } from "../api/merchant";
export default {
  name: "Login",
  components: {
    VanButton,
    VanForm,
    VanField,
    /*VanTab,
    VanTabs,*/
    //VanIcon,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      loginType: 0,
      username: "",
      password: "",
      vcode: null,
      confirmPwd: "",
      telephone: "",
      sms: "",
      token: "",
      smskey: null,
      active: 0,
      captchaCode: null,
      captchCodeId: "",
      vcodeButtonDisabled: false,
      vcodeText: "获取验证码",
      time: 0,
    };
  },
  methods: {
    onLogin(val) {
      const toast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: "登录中...",
      });
      if (this.loginType === 0) {
        login({
          username: val.username,
          password: val.password,
          captchaCode: val.vcode,
          captchCodeId: this.captchCodeId,
        })
          .then((res) => {
            toast.clear();
            if (!res.data) {
              this.getVerifyCode();
              this.$toast.fail(res.message);
            } else {
              this.vcode = "";
              Cookies.set("token", res.data.token);
              Cookies.set("mer_id", res.data.mch.org_id);
              Cookies.set("mch", JSON.stringify(res.data.mch));
              Cookies.set("userId", res.data.userId);
              this.$store.commit("base/SET_MERCHANT_ID", res.data.mch.org_id);
              this.$store.commit("base/SET_CURRENT_RESTAURANT", res.data.mch);
              this.$router.push("/merchantHome");
            }
          })
          .catch(() => {
            toast.clear();
            this.getVerifyCode();
            this.$toast.fail("登陆失败");
          });
      } else {
        loginOrRegister({
          phone: val.telephone,
          smsToken: this.token + "@" + this.sms,
        })
          .then((res) => {
            toast.clear();
            if (!res.data) {
              this.getVerifyCode();
              this.$toast.fail(res.message);
            } else {
              this.sms = "";
              Cookies.set("token", res.data.token);
              Cookies.set("mer_id", res.data.mch.org_id);
              Cookies.set("mch", JSON.stringify(res.data.mch));
              Cookies.set("userId", res.data.userId);
              this.$store.commit("base/SET_MERCHANT_ID", res.data.mch.org_id);
              this.$store.commit("base/SET_CURRENT_RESTAURANT", res.data.mch);
              this.$router.push("/merchantHome");
            }
          })
          .catch(() => {
            toast.clear();
            this.getVerifyCode();
            this.$toast.fail("登陆失败");
          });
      }
    },
    validatePhone() {
      return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.telephone);
    },
    validatePwd(val) {
      return val.length >= 6 && val.length <= 16;
    },
    comparePwd(val) {
      return val == this.password;
    },
    sendSMSCode() {
      var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(this.telephone)) {
        Notify({ type: "danger", message: "请输入正确的手机号" });
        return;
      }
      sendSMSCode({
        telephone: this.telephone,
        name: "test",
      })
        .then((res) => {
          this.$toast.success("发送成功");
          this.sms = res.debugCode;
          this.token = res.token;
          this.time = 60;
          this.vcodeButtonDisabled = true;
          this.countDown();
        })
        .catch(() => {
          this.$toast.fail("发送失败");
        });
    },
    countDown() {
      //验证码读秒计数
      if (this.time > 0) {
        this.time--;
        this.vcodeText = "获取验证码(" + this.time + ")";
        setTimeout(this.countDown, 1000);
      } else {
        this.time = 0;
        this.vcodeText = "获取验证码";
        this.vcodeButtonDisabled = false;
      }
    },
    getVerifyCode() {
      // 获取图形验证码
      getCaptcha().then((res) => {
        this.captchaCode = res.data.code;
        this.captchCodeId = res.data.captchCodeId;
      });
    },
  },
  created() {
    this.getVerifyCode();
  },
};
</script>

<style lang="less" scoped>
.van-form {
  min-height: calc(100vh);
  box-sizing: border-box;
  padding-top: 1rem;
  .pwd2sms {
    height: 15px;
    padding: 0rem 0.6rem;
    padding-bottom: 0;
    font-size: 0.5rem;
    font-weight: normal;
    color: @mch-color;
    text-align: right;
    span {
      cursor: pointer;
    }
  }
  .btn {
    margin: 25px 16px;
  }
}
.pageTitle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12rem;
  .title {
    font-weight: bold;
    font-size: 17px;
    color: #fff;
  }
}
/deep/ .van-button {
  border-radius: 0.3rem;
  background: @mch-color !important;
  border-color: @mch-color !important;
}
</style>
