import request from "@/utils/request";
export function login(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/login",
    method: "post",
    data: data,
  });
}
export function getCaptcha(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/getCaptchas",
    method: "post",
    data: data,
  });
}
export function selectCategoryList(merId) {
  return request({
    baseURL: "/SntWxMch",
    url: "/selectCategoryList",
    method: "post",
    data: { merId },
  });
}
export function addCategory(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/addCategory",
    method: "post",
    data: data,
  });
}
export function editCategory(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/editCategory",
    method: "post",
    data: data,
  });
}
export function deleteCategory(id) {
  return request({
    baseURL: "/SntWxMch",
    url: "/deleteCategory",
    method: "post",
    data: { id },
  });
}
export function selectGoodList(merId, cateId) {
  return request({
    baseURL: "/SntWxMch",
    url: "/selectGoodList",
    method: "post",
    data: { merId, cateId },
  });
}
export function addGood(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/addGood",
    method: "post",
    data: data,
  });
}
export function editGood(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/editGood",
    method: "post",
    data: data,
  });
}
export function deleteGood(id) {
  return request({
    baseURL: "/SntWxMch",
    url: "/deleteGood",
    method: "post",
    data: { id },
  });
}
export function uploadImage(file) {
  const formData = new FormData();
  formData.append("file", file.file);
  return request({
    baseURL: "/sysFileInfo",
    url: "/upload",
    method: "post",
    data: formData,
  });
}
export function offGood(id, status) {
  return request({
    baseURL: "/SntWxMch",
    url: "/offGood",
    method: "post",
    data: { id, status },
  });
}

/**
 * 发送短信验证码
 * @param data
 * @returns {*}
 */
export function sendSMSCode(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/sendSMSCode",
    method: "post",
    data: data,
  });
}

/**
 * 查询统计数据
 * @param data
 * @returns {*}
 */
export function statisticsData(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/statisticsData",
    method: "post",
    data: data,
  });
}

/**
 * 根据电话号码登录或注册
 * @param data
 * @returns {*}
 */
export function loginOrRegister(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/loginOrRegByPhone",
    method: "post",
    data: data,
  });
}

/**
 * 修改用户密码
 * @param data
 * @returns {*}
 */
export function modifyPwd(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/modifyPwd",
    method: "post",
    data: data,
  });
}

/**
 * 查询桌数状态/当日营业额
 * @param mer_id
 * @returns {*}
 */
export function operatingData(mer_id) {
  return request({
    baseURL: "/SntWxMch",
    url: "/operatingData",
    method: "post",
    data: { mer_id },
  });
}

/**
 * 商家端加菜下单
 * @param data
 * @returns {*}
 */
export function merchantPlaceOrder(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/merchantPlaceOrder",
    method: "post",
    data,
  });
}


